// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import 'what-input'
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()
import TurbolinksAdapter from 'vue-turbolinks';
Vue.use(TurbolinksAdapter);
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

import Vue from 'vue/dist/vue.esm';
import axios from 'axios';
// const axios = require('axios').default;
import VueAsyncComputed from 'vue-async-computed';
Vue.use(VueAsyncComputed);
Vue.prototype.$http = axios;

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js";
gsap.registerPlugin(ScrollToPlugin);


const components = [
  { name: "people-search", path: "people/search.vue" },
  { name: "people-search-result-cell", path: "people/search-result.vue" },
  { name: "collection-select", path: "people/collection-select.vue" },
  { name: "app-header", path: "shared/app-header.vue" },
  { name: "dev-panel", path: "shared/dev-panel.vue" },
  { name: "page-tools", path: "shared/page-tools.vue" },
  { name: "bio-tools", path: "shared/bio-tools.vue" },
  { name: "page-nav", path: "shared/page-nav.vue" },
  { name: "tabs", path: "shared/tabs.vue" },
  { name: "carousel", path: "shared/carousel.vue" },
  { name: "frontpage-header", path: "shared/frontpage-header.vue" },
  { name: "bio-nav", path: "shared/bio-nav.vue" },
  { name: "offices", path: "shared/offices.vue" },
  { name: "modal", path: "shared/modal.vue" },
  { name: "mail-modal", path: "shared/mail-modal.vue" },
  { name: "leaving-site-modal", path: "shared/leaving-site-modal.vue" },
  { name: "button-clipboard-copy", path: "shared/button-clipboard-copy.vue" },
  { name: "show-more", path: "shared/show-more.vue" },
  { name: "search-form", path: "shared/search-form.vue"},
  { name: "button-clipboard-copy", path: "shared/button-clipboard-copy.vue" },
  { name: "back-to-top", path: "shared/back-to-top.vue" },
];

for(let definition of components) {
  let path = definition.path;
  let name = definition.name;

  if (!name) {
    name = path.split("/").pop();
    name = name.split(".").shift();
  }
  var loaded = require(`components/frontend/${path}`).default;
  Vue.component(name, loaded);
}

Vue.filter("formatDate", function (value, format, message) {
  if (!value) { return message; }
  if (!format || format === "default") {
    format = 'MMMM DD, YYYY, h:mm:ssa';
  }
  return moment(value).format(format);
});

window.Vue = Vue;

document.addEventListener('turbolinks:load', () => {
  let node = document.getElementById("app");
  window.application = new Vue({
    // render: h => h(AdminApp),
    el: node
  });

  // console.log(app);
})

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Halt Animation on Resize + Set App Height
// Apply window-resizing class to halt animations during resize
// Prevents triggering transitionend when not desired (and also looks nicer).
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

import debounce from 'lodash.debounce';

const appHeight = () => {
  document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
}

appHeight();
window.addEventListener('resize', appHeight);

//document.addEventListener('turbolinks:load', () => {
  //documentHeight();
//});

const body = window.document.body;

const startResize = () => {
  body.classList.add('window-resizing');
}

const stopResize = () => {
  body.classList.remove('window-resizing');
  //documentHeight();
}

window.addEventListener('resize', debounce(startResize, 500, {
  'leading': true,
  'trailing': false
}));

window.addEventListener('resize', debounce(stopResize, 500, {
  'leading': false,
  'trailing': true
}));

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Bypass Blocks
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

document.addEventListener('turbolinks:load', () => {

  const bypassButton = document.getElementById('bypassButton');

  if(bypassButton) {

    const content = document.getElementById('content');

    bypassButton.addEventListener("click", () => {

      window.scrollTo({ top:80, left:0, behavior: "instant"});
      content.setAttribute("tabindex", "-1");
      content.focus({
        preventScroll: true
      });
      content.removeAttribute("tabindex");

    });

  }

});

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Show More / Less
// For Button: data-showmore="{{region_id}}" to button
// For Show/Hide Region: id="{{region_id}}" data-showmore-region
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

document.addEventListener('turbolinks:load', () => {

  const btnShowMore = document.querySelectorAll('[data-showmore]');

  for (const btn of btnShowMore) {

    let regionId = btn.dataset.showmore;
    let region = document.getElementById(regionId);

    let windowPosition = null;

    region.ontransitionend = (e) => {
      if(e.propertyName === 'height') {
        region.classList.remove('showmore-transition');
        region.classList.toggle('showmore-visible');
        region.style.height = null;
      }
    };

    btn.addEventListener("click", () => {
      if(region.classList.contains('showmore-visible')) {
        region.style.height = `${region.scrollHeight}px`;
        window.setTimeout(() => {

          region.classList.add('showmore-transition');
          region.style.height = 0;
          btn.classList.remove('active');

          // Scroll user back to original position

          if(windowPosition) {
            if(window.scrollY > windowPosition) {
              gsap.to(window, {duration: .25, scrollTo: windowPosition});
            }
          }

        });
      } else {
        windowPosition = window.scrollY;
        region.classList.add('showmore-transition');
        region.style.height = `${region.scrollHeight}px`;
        btn.classList.add('active');
      }
    });
  }

  // @ TD / Supplant Widget Functionality with universal if possible

  const elShowMore = document.querySelectorAll('[data-widget]');

  if(elShowMore) {
    for (const el of elShowMore) {

      let windowPosition = null;

      const toggleshowMore = el.querySelector('.btn-showmore-toggle');
      const toggleAccordion = el.querySelector('.accordion-toggle');

      if (toggleshowMore) {
        toggleshowMore.addEventListener("click", function() {
          el.classList.toggle('showmore-visible');
        });
      }

      if (toggleAccordion) {
        toggleAccordion.addEventListener("click", function() {
          el.classList.toggle('accordion-visible');
        });
      }

    };
  }

});

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Copy to Clipboard
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

document.addEventListener('turbolinks:load', () => {
  const elClipboard = document.querySelectorAll('[data-clipboard]');
  if(elClipboard) {
    for (const el of elClipboard) {
      const target = document.getElementById(el.dataset.clipboard);
      el.addEventListener("click", () => {
        navigator.clipboard.writeText(target.textContent).then(function() {
          // @TD Clipboard (Tooltip)
        }, function() {
          // Fail
        });
      });
    }
  }
  (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
    const $notification = $delete.parentNode;

    $delete.addEventListener('click', () => {
      $notification.parentNode.removeChild($notification);
    });
  });

});

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Testimonial Swiper
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

import Swiper, { Navigation, Keyboard, A11y, EffectFade, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Keyboard, A11y, EffectFade, Pagination, Scrollbar]);

document.addEventListener('turbolinks:load', () => {

  const elStaff = document.querySelector('.staff-init');
  const elCustomer = document.querySelector('.customer-init');

  if(elStaff) {
    const staffSwiper = new Swiper('.staff-init', {
      slidesPerView: 1,
      a11y: {
        enabled: true,
      },
      /*
        effect: 'fade',
        fadeEffect: {
          crossFade: false
        },
      */
      navigation: {
        nextEl: '.staff-next',
        prevEl: '.staff-prev'
      },
      pagination: {
        el: '.staff-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }

  if(elCustomer) {
    const customerSwiper = new Swiper('.customer-init', {
      slidesPerView: 1,
      a11y: {
        enabled: true,
      },
      navigation: {
        nextEl: '.customer-next',
        prevEl: '.customer-prev'
      },
      pagination: {
        el: '.customer-pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }

});

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Homepage Feature Slider
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

document.addEventListener('turbolinks:load', () => {

  const elFeatured = document.querySelector('.feature-carousel-swiper');

  if(elFeatured) {

    const elFeaturedNext = elFeatured.querySelector('.swiper-button-next');
    const elFeaturedPrev = elFeatured.querySelector('.swiper-button-prev');


    const featuredPostsSwiper = new Swiper('.feature-carousel-swiper', {
      watchSlidesProgress: true,
      a11y: {
        enabled: true,
      },
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
      navigation: {
        nextEl: elFeaturedNext,
        prevEl: elFeaturedPrev,
      },
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 24,
          scrollbar: {
            enabled: true
          },
          navigation: {
            enabled: false
          }
        },
        // md
        640: {
          slidesPerView: 2,
          spaceBetween: 32,
          scrollbar: {
            enabled: true
          },
          navigation: {
            enabled: false
          }
        },
        // lg
        1024: {
          slidesPerView: 3,
          spaceBetween: 24,
          scrollbar: {
            enabled: false
          }
        },
        // xl
        1360: {
          slidesPerView: 4,
          spaceBetween: 32,
          scrollbar: {
            enabled: false
          }
        }
      }
    });

  }

});

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Menu: Desktop Level 2
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

import { state } from '../components/frontend/shared/state';

document.addEventListener('turbolinks:load', () => {

  const elSideMenu = document.querySelector('[data-sidemenu]');

  if(elSideMenu) {

    let subMenus = elSideMenu.querySelectorAll('.has-submenu');

    for (let subMenu of subMenus) {

      let subMenuLink = subMenu.querySelector('[data-openmenu]');

      subMenuLink.addEventListener('click', (e) => {
        if (state.menu === 'mobile') return;
        e.preventDefault();

        // Any Other Open SubMenus
        for (let el of subMenus) {
          el.classList.remove('menu-active');
          el.querySelector('[data-openmenu]').setAttribute('aria-expanded', 'false');
        }

        // Activate Current
        subMenu.classList.add('menu-active');
        subMenu.querySelector('[data-openmenu]').setAttribute('aria-expanded', 'true');

      });
    }

  }

  const elMobileMenus = document.querySelectorAll('[data-mobilemenu]');

  if(elMobileMenus) {

    for (let elMobileMenu of elMobileMenus) {

      let elTrigger = elMobileMenu.querySelector('.page-nav-trigger');
      let subMenus = elMobileMenu.querySelectorAll('.has-submenu-sm, .has-submenu');

      elTrigger.addEventListener('click', (e) => {
        if (state.menu !== 'mobile') return;
        elMobileMenu.classList.toggle('mobile-active');
      });

      for (let subMenu of subMenus) {

        let subMenuLink = subMenu.querySelector('[data-openmenu]');

        subMenuLink.addEventListener('click', (e) => {
          if (state.menu !== 'mobile') return;
          e.preventDefault();
          subMenu.classList.toggle('mobile-menu-active');
        });

      }

    }

  }

  // Page Navigation Menus (All Breakoin ts)
  
  const elNavMenus = document.querySelectorAll('[data-navmenu]');
  
  console.log(elNavMenus);
  
  if(elNavMenus) {

    for (let elNavMenu of elNavMenus) {
    
      console.log('elNavMenu', elNavMenu);

      let elTrigger = elNavMenu.querySelector('.page-nav-trigger');
      let elList =    elNavMenu.querySelector('.page-nav-list');
      
      console.log('elNavMenu', elNavMenu);
      console.log('elList', elList);

      elTrigger.addEventListener('click', (e) => {
        elNavMenu.classList.toggle('mobile-active');
        elList.classList.toggle('active');
      });
    }

  }

});

// console.log('frontend.js loaded');

/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/
// Hubspot Scroll Back to Top After Form Submission 
/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -*/

window.addEventListener('message', event => {
  if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {

    const formEl = document.querySelector('.hbspt-form');

    // formEl.scrollIntoView(); // Mobile Safari Unhappy.
    gsap.to(window, { duration: .25, scrollTo:{ y: formEl, offsetY: 90 } });

   }
});
