<script>
  import TwitterXIcon from '../svg-components/twitter-x.vue';
  import FacebookIcon from '../svg-components/facebook.vue';
  import LinkedInIcon from '../svg-components/linked-in.vue';
  import MailIcon from '../svg-components/mail.vue';

  export default {
    components: {
      'facebook-icon': FacebookIcon,
      'twitter-x-icon': TwitterXIcon,
      'linked-in-icon': LinkedInIcon,
      'mail-icon': MailIcon
    },
    data: ()=>({
      shareOpen: false,
      pageLoaded: false
    }),
    mounted() {
      window.addEventListener("load", (event) => {
        this.pageLoaded = true;
      });
    },
    props: {
      baseUrl: {
        type: String,
      },
      bioLinks: {
        type: Object,
      },
      options: {
        type: Object
      }
    },
    computed: {
      fb_url: function() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.url}`;
      },
      twitter_url: function() {
        return `https://x.com/share?url=${this.url}&text=${this.rawTitle}`;
      },
      linkedin_url: function() {
        return `https://www.linkedin.com/shareArticle?mini=true&url=${this.url}&title=${this.rawTitle}&source=FR.com`;
      },
      email_url: function() {
        return `mailto:?subject=Thought this might interest you...&body=${this.emailBody}`;
      },
      emailBody: function() {
        let body = '';
        body += this.rawTitle;
        body += '%0d%0a%0d%0a\n\n';
        
        /*
        if(this.description) {
          body += this.rawDescription;
          body += '%0d%0a%0d%0a\n\n';
        }
        */

        body += this.url;
        
        return body;
      },
      url: function() {
        return this.bioLinks.url;
      },
      title: function() {
        return this.bioLinks.title;
      },
      rawTitle: function() {
        let pageTitle = this.bioLinks.title || ''
        return this.encodeTitle(pageTitle);
      },
      description: function() {
        let bioDesc = this.bioLinks.description;
        return bioDesc?.replace(/<\/?[^>]+>/ig, " ");
      },
      rawDescription: function() {
        return this.encodeTitle(this.description);
      },
      locale: function() {
        if (this.options && this.options.locale) {
          return this.options.locale;
        }
        return "en";
      },
      linkedInUrl: function() {
        return this.bioLinks?.linked_in
      },
      vcardUrl: function() {
        if (this.bioLinks && this.bioLinks.pdf) {
          return this.bioLinks.vcf + "/?" + this.localeQueryParam;
        }
      },
      pdfUrl: function() {
        if (this.bioLinks && this.bioLinks.pdf) {
          return this.bioLinks.pdf + "?" + this.localeQueryParam;
        }
      },
      localeQueryParam: function() {
        return "locale=" + this.locale;
      },

    },
    methods: {
      printPage: function() {
        // console.log("Printing page", window);
        window.print();
      },
      encodeTitle(str) {
        str = str.replace(/<\/?[^>]+>/ig, " ");
        str = str.replace(/&amp;/g, '&');
        str = escape(str);
        return str;
      }
    }
  }

</script>

<template>

  <nav class="bio-social" :class="{'share-open' : shareOpen}">
    <ul aria-label="Bio Tools" class="social bio-social-list">
      <li class="vcard"><a :href="vcardUrl" aria-label="Download V Card"><span class="icon-vcard" aria-hidden="true"></span></a></li>
      <li class="pdf"><a :href="pdfUrl" aria-label="Download PDF" target="_blank"><span class="icon-download-pdf" aria-hidden="true"></span></a></li>
      <li class="print" aria-label="Print Page" v-if="pageLoaded">
        <button @click="printPage()"><span class="sr-only">Print Page</span><span class="icon-printer" aria-hidden="true"></span></button>
      </li>
      <li class="share" aria-label="Toggle Page Sharing Menu">
        <button
          @click="shareOpen =! shareOpen"
          aria-label="Toggle Page Sharing Options"
          aria-haspopup="true"
          :aria-expanded="shareOpen ? 'true' : 'false'"
          aria-controls="shareElements"
        >
          <span class="circle-icon hover-icon" v-if="shareOpen" aria-hidden="true">
            <span class="icon-close-sm-circle off"></span>
            <span class="icon-close-sm-circle-solid on"></span>
          </span>
          <span class="icon-share" aria-hidden="true" v-else></span>
        </button>
        <ul aria-label="Share This Page" class="share bio-social-share" :class="{active : shareOpen }" id="shareElements">
          <li aria-label="Facebook"><a target="_blank" :href="fb_url"><span aria-hidden="true">
            <facebook-icon />
          </span></a></li>
          <li aria-label="Twitter"><a  target="_blank" :href="twitter_url"><span aria-hidden="true">
            <twitter-x-icon />
          </span></a></li>
          <li aria-label="LinkedIn"><a target="_blank" :href="linkedin_url" ><span aria-hidden="true">
            <linked-in-icon />
          </span></a></li>
          <li aria-label="Email"><a target="_blank" :href="email_url" ><span aria-hidden="true">
            <mail-icon />
          </span></a></li>
        </ul>
      </li>
      <li class="linkedin" aria-label="Linked In Profile" v-if='linkedInUrl'><a :href="linkedInUrl" target='_blank'>
          <span aria-hidden="true">
            <linked-in-icon />
          </span>
        </a>
      </li>
    </ul>
  </nav>

</template>
